:root {
    --main-color: #ff0000; /* Красный цвет - замените на свой цвет */
    --secondary-color: #00ff00; /* Зеленый цвет - замените на свой цвет */
    --main-black: #171717;
    --secondary-black: #1a181d;
    --white: #fff;
    --yellow: #fcb813;
    --grey: #b1b1b1;
}
html, body {
    height: 100%;
    font-family: Unbounded;
}
* a {
    text-decoration: none;
    color: inherit;
    display: block;
}
* {
    margin: 0;
    padding: 0;
}
h2,
h5 {
    margin: 0;
    padding: 0;
}
p {
    fontweight: 400;
    margin: 0;
    padding: 0;
}