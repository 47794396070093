@import "../../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;
}

.block_foto {
  margin-top: 42px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  overflow-x: auto;
  overflow-y: clip;

}

.main_foto {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.top_foto {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.border_big_img {}

.border_big {
  position: relative;
  width: 200px;
  height: 260px;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;

  .border_big_img {
    margin-top: 20px;
    width: 120px;
  }
}

.text16B_name {
  margin-top: 20px;
}

.text16B_ {
  margin-top: 0px;
}

.border_main_img {}

.border_main {
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1;
  position: relative;
  width: 310px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;

  .border_main_img {
    margin: 0px 20px 0 20px;
  }
}

.border_main2_img {}

.border_main2 {
  position: relative;
  width: 310px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 1;

  .border_main2_img {
    margin: 0px 20px 0 20px;
  }
}

.bottom_foto {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.border_foto_img {}

.border_foto {
  width: 90px;
  height: 120px;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;


  .border_foto_img {
    height: 120px;
    width: 90px;
    z-index: -1;
    border-radius: 30px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0px 0;
    padding: 0 20px;
  }

  .ourTeam {
    margin-top: 80px;
  }

  .title {
    font-size: 34px;
  }

  .block_text {
    max-width: 250px;
  }
}

@media (max-width: 500px) {
  .ourTeam {
    margin-top: 80px;
  }

  .border_foto {
    justify-content: center;
  }

  .text16B_name {
    margin-top: 20px;
  }

  .top_foto {
    display: block;
    flex-wrap: wrap;
    gap: 20px;
  }
}