@import "../../stylesGlobal/globalStyles.module.css";

.section {
  overflow: hidden;
}

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;
}

._title {
  margin-top: 20px;
  max-width: 700px;
}

._text12Fw4 {
  margin-top: 25px;
  max-width: 420px;
}

.btn_text14Fw4 {
  padding-right: 40px;
}

.block_wraper {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.bottom_line_img {
  height: 40px;
  background-image: url("../../common/svg/line_bottom.png");
}

@media (max-width: 1024px) {

  ._button {
    max-width: 620px !important;
    width: 620px !important;
  }

  .btn_yellow {
    max-width: 620px !important;
  }

  .btn_text14Fw4 {
    padding-right: 55px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px;
  }

  ._button {
    max-width: 420px !important;
    width: 420px !important;
  }

  .btn_yellow {
    max-width: 420px !important;
  }
}

@media (max-width: 424.8px) {
  .block_news {
    align-items: center;
    margin-top: 30px;
  }

  ._button {
    min-height: 100px;
    /* width: 420px !important; */
  }

  .btn_text14Fw4 {
    padding: 10px 50px 10px 0;
  }
}