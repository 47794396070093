/** a {*/
/*  text-decoration: none;*/
/*  color: inherit;*/
/*  display: block;*/
/*}*/
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*h2,*/
/*h5 {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*p {*/
/*  fontweight: 400;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/

.p {
  fontweight: 400;
  margin: 0;
  padding: 0;
}
.for_what_wraper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;
}
.subtitle {
  color: #b1b1b1;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.7px;
}
.title {
  font-family: Unbounded;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 62.4px */
  text-transform: uppercase;
  margin-right: 20px;
}
.block_text {
  max-width: 500px;
  margin-left: 20px;
 
  .p {
    color: #171717;
    font-family: Unbounded;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.7px;
  }
}
.text_bottom {
  font-size: 12px;
  font-weight: 400;
  margin-top: 20px;
}


@media (max-width: 768px) {
  .container {
    margin: 0;
    padding: 70px 20px 0;
  }
  .title {
    font-size: 34px;
  }
  .block_text {
    max-width: 250px;
}
}
@media (max-width: 500px) {
  .container {
    margin: 0px 0;
  }
  .for_what_wraper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
  }
  .title {
    margin-right: 0px;
  }
  .block_text {
    margin-top: 20px;
    margin-left: 0px;
}
}
