@import "../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;
}

._title {
  margin-top: 20px;
  max-width: 450px;
}

.title_color {
  color: var(--yellow);
}

.title_wraper {
  display: flex;
}

.btn_wraper {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 72px;
}

.button_thank {
  display: flex;
  height: 60px;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  padding: 20px;
  align-items: center;
}

.block_email {
  margin-top: 110px;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.text_email {
  padding: 60px 0 37px;
  color: var(--main-black, #171717);
  text-align: center;
  font-family: Unbounded;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  width: 100%;
}

.button_copy {
  position: relative;
  display: flex;
  height: 80px;
  width: 640px;
  border: 1px solid #e2e2e2;
  background: var(--white, #fff);
  border-radius: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 67px;
  font-family: Unbounded;
}

.round_bg_img {}

.round_bg {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  background-color: black;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .round_bg_img {
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  ._button {
    max-width: 620px !important;
    width: 620px !important;
  }

  .btn_yellow {
    max-width: 620px !important;
  }

  .btn_text14Fw4 {
    padding-right: 55px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px;
  }

  .title_wraper {
    flex-direction: column;

  }

  .btn_wraper {
    justify-content: flex-start;
    padding-top: 30px;
  }

  .button_thank {
    width: 80%;
  }

  .block_email {
    margin-top: 80px;
    position: relative;
  }

  .text_email {
    font-size: 18px;
    width: 380px;
    padding-top: 40px;
  }

  .email_section {
    display: flex;
    border: 1px solid #e2e2e2;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    background: var(--white, #fff);
  }

  .button_copy {
    width: 75%;
    margin-bottom: 110px;
    justify-content: space-between;
    height: 80px;

  }

  .round_bg {
    position: absolute;
    transform: translate(-10px, 60px);
  }
}

@media (max-width: 500px) {
  .button_thank {
    width: 100%;
    height: 100%;
  }

  .text_email {
    width: 280px;
  }

  .button_copy {
    font-size: 14px;
    
    div {
      position: absolute;
      right: 0;
      bottom: -80px;
    }
  }

  .btn_text14Fw4 {
    padding: 10px 55px 10px 0;
  }
}