@import "../../stylesGlobal/globalStyles.module.css";

.payInfo {
  overflow: hidden;
}

.payInfo_wraper {
  display: flex;
  justify-content: space-between;
}

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;
}

.left_block_h2 {
  font-family: Unbounded;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 62.4px */
  text-transform: uppercase;
}

.left_block_p {
  color: #171717;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.7px;
  margin-top: 40px;
  max-width: 530px;
}

.left_block_h5 {
  color: #b1b1b1;
  font-family: Unbounded;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.7px;
  margin-top: 110px;
}

.left_block {
  width: 50%;
  margin-right: 20px;

  .left_block_h2 {
    font-family: Unbounded;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 62.4px */
    text-transform: uppercase;
  }

  .left_block_p {
    color: #171717;
    font-family: Unbounded;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.7px;
    margin-top: 40px;
    max-width: 530px;
  }

  .left_block_h5 {
    color: #b1b1b1;
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.7px;
    margin-top: 110px;
  }
}

.btns_wrapper {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.btn_coffee_img {}

.btn_coffee {
  position: relative;
  display: flex;
  width: 200px;
  height: 60px;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
  cursor: pointer;
  background: #ffffff;

  .btn_coffee_img {
    margin: 3px 0 0 6px;
  }
}

.btn_coffee p {
  position: absolute;
  left: 60px;
  font-size: 12px;
  margin-top: 0px;
  padding-right: 10px;
  font-weight: 400;
  line-height: 16px;
}

.cicle_img {}

.cicle {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .cicle_img {
    margin: 0 auto;
  }
}

.right_block {
  width: 53%;
  margin-left: 20px;
}

.payInfo_requisites {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.border_logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100px;
  border-radius: 40px;
  border: 1px solid #e2e2e2;
  cursor: pointer;

  .border_logo_img {
    margin: 0 auto;
  }
}

.paypal_img {
  width: 60px;
  height: 46px;
}

.bitcoin_img {
  width: 60px;
  height: 60px;
}

.mono_img {
  width: 70px;
  height: 30px;
}

.name_method_span {}

.name_method {
  text-align: center;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 425px) {
    font-size: 12px;
  }
}

/* FormUAH component*/
.requisites_wrapper {
  margin-top: 30px;

  .mono_item:not(:first-child) {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

._button_copy {
  margin-top: 10px;
  min-height: 80px;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
}

._button_copy {
  justify-content: flex-start;
}


._text10Fw4 {
  padding-bottom: 6px;
}

._text14Fw4 {
  font-family: Unbounded;
  padding-right: 50px;
}

/* FormMONO component*/
.mono_item {
  width: 100%;
  /* flex-shrink: 0; */
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.mono_left {
  display: flex;
  flex-direction: column;
  margin-right: 45px;
}

.money_text {
  margin-top: auto;
}

.price_text {
  white-space: nowrap;
}

._text18B {
  max-width: 160px;
}

.number_card {
  padding-right: 60px;
}

.round_mono {
  right: 1px;
}

.mono_right {
  /* max-width: 320px; */
  width: 100%;
  /* padding-left: 100px; */
}

._text10Fw4 {
  color: var(--main-black, #171717);
}

.flex_wrapper {
  display: flex;
  position: relative;
}

.card_wrapper {
  margin-top: 12px;
}

.button_link {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 100%;
  border: 1px solid var(--yellow);
  border-radius: 170px;
  background-color: var(--yellow);
  text-align: center;
  cursor: pointer;
  margin-top: 22px;
}

.round_bg_mono_img {}

.round_bg_mono {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* border: 1px solid #E2E2E2; */
  background-color: var(--main-black);

  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;

  .round_bg_mono_img {
    margin: 0 auto;
  }
}

.text14Fw4_mono {
  padding-left: 45px;
}

.crypto_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 60px;
  text-align: left;
  max-width: calc(30vw + (100vw - 105vw));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text_wrapper {
  padding-left: 20px;
}

.iban_text {
  word-break: break-all;
  padding-right: 70px;
}

.bottom_line_img {
  background-image: url("../../common/svg/line_bottom.png");
  height: 40px;
}



@media (max-width: 1024px) {
  .payInfo_wraper {
    display: flex;
    flex-direction: column;
  }

  .left_block {
    width: 100%;

    .left_block_h5 {
      text-align: center;
    }
  }

  .btns_wrapper {
    justify-content: space-around;
  }

  .right_block {
    width: 100%;
    margin-left: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mono_item {
    max-width: 640px;
  }

  .requisites_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .crypto_text {
    max-width: calc(30vw + (100vw - 87vw));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 60px 0;
    padding: 0 20px;
  }

  .left_block {
    width: 100%;

    .left_block_h5 {
      text-align: left;
    }

    .left_block_h2 {
      font-size: 34px;
    }
  }

  .right_block {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .btns_wrapper {
    justify-content: flex-start;
  }

  .payInfo_requisites {
    gap: 10px;
  }

  .border_logo {
    width: 65px;
    height: 55px;
    border-radius: 20px;
  }

  .name_method {
    .name_method_span {
      display: none;
    }
  }

  .mono_img {
    width: 36px;
    height: 15px;
  }

  .paypal_img {
    width: 32px;
    height: 24px;
  }

  .bitcoin_img {
    width: 32px;
    height: 32px;
  }

  ._button_copy {
    max-width: 485px;
    justify-content: flex-start;

    @media (max-width: 550px) {
      width: calc(100% - 40px);
    }
  }

  .text_wrapper {
    margin-left: 0px;
  }

  .crypto_text {
    max-width: calc(35vw + (100vw - 85vw));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mono_item {
    flex-direction: column;
    padding: 10px;
    max-width: 485px;
  }

  .mono_right {
    margin-top: 30px;
  }

  .money_text {
    margin-top: 20px;
  }

}

@media (max-width: 424.8px) {
  .left_block {
    .left_block_h2 {
      font-size: 30px;
    }
  }

  .right_block {
    width: 100%;
    align-items: center;
  }

  .payInfo_requisites {
    width: 100%;
    gap: 5px;
    justify-content: space-between;
    /* flex-wrap: nowrap; */
  }

  .border_logo {
    flex: 1;
    height: 47px;
  }


  .btns_wrapper {
    flex-direction: column;
  }

  .btn_coffee {
    width: 100%;
  }

  .paypal_email {
    word-break: break-all;
    padding-right: 50px;
  }

  ._button_copy {
    justify-content: flex-start;
  }

  .crypto_text {
    max-width: calc(45vw + (100vw - 87vw));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}