.header_icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.nav_Menu {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.aboutUs_section {
  /* height: 100%; */
  background-image: url("../../common/img/dark_bg.png");
  position: relative;
  overflow: hidden;
}

.aboutUs_img {
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;

  background-image: url('../../common/img/about_us_bg.png');
  /* Adjust the path if needed */

  max-width: 100%;
  max-height: 100%;
  height: 100%;
  /* Set background size, position, and repeat */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  /* Adjust as needed */

  /* Set other styles for the div */
  width: 100%;
  /* Adjust as needed */

}

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 0 auto;

}

.block_title {
  padding-top: 330px;
}

._title_aboutUs {

  color: var(--white);
  max-width: 330px;

}

.title_color {
  color: var(--yellow);
  white-space: nowrap;
}

.subtitle {
  padding: 20px 0 120px;
  color: var(--white);
  max-width: 440px;
  font-family: Unbounded;

}

@media (max-width: 768px) {
  .aboutUs_section {
    height: 100vh;
  }

  .aboutUs_img {
    height: 50%;
    bottom: 0;
    right: 0;

    background-image: url('../../common/img/about_us_mobile.svg');
    /* Adjust the path if needed */

    max-width: 100%;
    max-height: 100%;
    height: 60%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .container {
    padding: 0 20px;
  }

  .block_title {
    padding-top: 140px;
  }

  ._title_aboutUs {
    font-size: 38px;
  }

  .title_color {
    font-size: 38px;
  }

  .subtitle {
    position: relative;
    z-index: 10;
    max-width: 100%;
    margin: 0 -20px;
    padding: 20px 50% 200px 20px;
    background: #171717;
    background: linear-gradient(to bottom, rgba(23,23,23,1) 0%,rgba(23,23,23,1) 50%,rgba(255,255,255,0) 100%);
  }

}

@media (max-width: 500px) {
  .aboutUs_section {
    height: 100vh;
  }

  .subtitle {
    padding: 20px 20px 200px 20px;
  }
}