@import "../../../stylesGlobal/globalStyles.module.css";

.section {
  /* background-image: url("../common/img/our.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1); */
  /* opacity: 0.4; */
  background-color: #000;
}

.container {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0 70px;
}

.section_wraper {
  display: flex;
  justify-content: space-between;
}

.block_left {
  max-width: 380px;
  margin-right: 60px;
  padding: 130px 0;
}

.block_right {
  max-width: 960px;
  overflow: hidden;
  padding: 130px 0;
}

.video_content {
  max-width: 90%;
}

.img_content_img {}

.img_content {
  max-width: 100%;
  cursor: pointer;

  .img_content_img {
    width: 100%;
  }
}

._title {
  color: var(--white, #fff);
}

.subtitle {
  color: var(--grey, #fff);
  margin-top: 25px;
  max-width: 350px;
}

.block_button {
  margin-top: 90px;
  display: flex;
}

/* Component ButtonYellow in TalkAboutUs */
.button {
  display: block;
  position: relative;
  height: 60px;
  width: 170px;
  border: 1px solid #000000;
  border-radius: 168px;
  background-color: var(--yellow);
  cursor: pointer;
}

.button_bottom {
  display: none;
  margin-top: 20px;
}

.cicle {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  left: 10px;
  top: 50%;

  .circle_img {
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
}

.text_name {
  margin-left: 30px;
}

/* ================================================ */
.block_right {
  /* display: flex; */
}

.video_content {
  /* width: 100%;
  height: 100%; */
}

.img_content {
  /* width: 100%; */
  position: relative;

  .img_content_img {}
}

._text14B {
  color: var(--white);
  margin-top: 20px;
}

.round_button_img {}

.round_button {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--yellow);
  display: flex;
  align-items: center;
  right: -10px;
  bottom: 20px;
  transform: translate(-50%, 0);

  .round_button_img {
    margin: 0 auto;
    width: 20px;
    height: 20px;
  }
}

.arrow_left {
  position: absolute;
  right: 80px;
  bottom: -80px;
  background-color: transparent;
  border-color: rgb(72, 72, 72);
}

.arrow_right {
  position: absolute;
  right: 0;
  bottom: -80px;
  background-color: transparent;
  border-color: rgb(72, 72, 72);
}

@media (max-width: 1180px) {
  .block_right {
    max-width: 420px;
    /* display: block; */
  }

  .video_content {
    max-width: 90%;
  }

  .img_content {
    max-width: 100%;

    .img_content_img {
      width: 100%;
    }
  }
}

@media (max-width: 1023.8px) {
  .block_left {
    max-width: 100%;
    margin-right: 30px;
  }

  .block_right {
    flex-direction: column;
    gap: 30px;
  }

  .img_content {
    height: 100%;
  }
}

@media (max-width: 900px) {
  .container {
    margin-top: 80px;
  }

  .section_wraper {
    padding: 70px 0;
    display: block;
  }

  .block_left {
    padding-bottom: 30px;
    margin-right: 0px;
    padding: 80px 0;
  }

  .round_button {
    height: 60px;
    width: 60px;
    right: -15px;
    bottom: 20px;
  }

  ._text14B {
    margin-left: 50px;
  }

  .button_bottom {
    display: block;
  }

  .button_top {
    display: none;
  }

  .block_right {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 80px 0;
  }

  .video_content {
    max-width: 100%;
  }

  .img_content {
    .img_content_img {
      width: 100%;
    }
  }
}

@media (max-width: 475px) {
  .container {
    padding: 0 20px;
  }

  .block_left {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .block_right {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .video_content {
    max-width: 100%;
  }

  .img_content {
    padding: 0px;

    .img_content_img {
      width: 100%;
    }
  }

  ._text14B {
    margin-left: 30px;
  }

  .round_button {
    right: 0;
    height: 40px;
    width: 40px;
  }
}