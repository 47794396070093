@import "../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 100px auto;

  @media (max-width: 500px) {
    margin-bottom: 0;
  }
}

._title {
  margin-top: 20px;
  max-width: 1100px;
}

.title_color {
  color: var(--yellow);
}

.content_wraper {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.block_text {
  min-width: 280px;
  flex: 1;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
}

._text12Fw4 {
  margin-top: 20px;
}

.news_item {
  margin-left: 60px;
  max-width: 310px;
}

._button {
  background-color: var(--yellow);
  width: 410px;
  margin-top: 30px;
}

.top_text {
  position: absolute;
  left: 60px;
  margin-top: -20px;
  padding-right: 10px;
}

.bottom_text {
  position: absolute;
  left: 60px;
  margin-top: 30px;
  padding-right: 10px;
}

.block_wraper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 50px;
}

.block_right_img {}

.block_right {
  flex-direction: column;
  align-items: center;

  .img_foto1 {
    margin-right: 20px;
  }

  .block_right_img {
    border-radius: 150px;
  }
}

.img_foto {
  background-image: url("../../common/img/Fund_img1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 305px;
  width: 200px;
  border-radius: 150px;
}

@media (max-width: 1024px) {
  .content_wraper {
    display: block;
    width: 100%;
  }

  .block_right {
    flex-direction: column;
    align-items: center;

    .img_foto1 {
      margin-bottom: 20px;
      margin-right: 0px;
    }
  }

  .img_foto1 {
    width: 50%;
  }

  .img_foto2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px;
  }

  .block_wraper {
    display: block;
    margin-top: 50px;
  }

  .block_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }

  ._title {
    font-size: 30px;
  }

  .content_wraper {
    display: block;
    width: 100%;
  }

  ._button {
    width: 390px;
  }

  .block_right {
    display: flex;
    justify-content: center;
  }

  .img_foto1 {
    display: none;
  }

  .img_foto2 {
    margin-top: 40px;
    height: 215px;
    width: 335px;
  }
}

@media (max-width: 500px) {
  ._title {
    font-size: 23px;
  }

  .block_wraper {
    display: block;
    margin-top: 50px;
  }

  .content_wraper {
    display: block;
    width: 100%;
  }

  ._button {
    max-width: 320px;
  }

  .img_foto2 {
    margin-top: 40px;
    height: 170px;
    width: 280px;
  }

  .top_text {
    left: 30px;
    zoom: .95;
  }

  .bottom_text {
    left: 30px;
    zoom: .95;
  }

  ._round_bg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}