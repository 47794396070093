.ToBePartner_section {
  height: 100%;
  background-image: url("../../common/img/dark_bg.png");
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 0;
  /* z-index: -2; */
}

.wrapper {
  max-width: 100%;
  width: 1440px;
  position: relative;
}

.header_icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ToBePartner_img {
  position: absolute;
  height: 90%;
  bottom: 0;
  right: 0;
  max-width: 100%;
  width: 60%;
  /* z-index: -1; */
}

.container {
  max-width: 1440px;
  padding: 190px 70px 0 70px;
  margin: 0 auto;
  position: sticky;
  z-index: 50;
}

.block_title {
  margin: 100px 0 150px;
  /* padding-top: 100px; */
  z-index: 2;
}

._title_aboutUs {
  color: var(--white);
  max-width: 330px;
  z-index: 2;
}

.title_color {
  color: var(--yellow);
  white-space: nowrap;

}

.subtitle {
  padding-top: 20px;
  color: var(--white);
  max-width: 440px;
}

@media (max-width: 1024px) {
  .ToBePartner_img {
    bottom: 0px;
    right: 0;
    height: 500px;
    width: 700px;
  }

  ._title_aboutUs {
    font-size: 55px;
  }

  .subtitle {
    max-width: 340px;
  }
}

@media (max-width: 768px) {
  .ToBePartner_section {
    height: 100vh;
  }

  .block_title {
    margin: 80px 0 45%;
    z-index: -1;
  }

  .ToBePartner_img {
    bottom: 0px;
    right: 0;
    z-index: 12;
  }

  ._title_aboutUs {
    font-size: 38px;
  }

  .title_color {
    font-size: 38px;
  }

  .subtitle {
    max-width: 330px;

  }
}

@media (max-width: 500px) {
  .ToBePartner_section {
    height: 100vh;
  }

  .container {
    padding: 150px 20px 0 20px;
  }

  .block_title {
    margin: 0;
  }

  .ToBePartner_img {
    left: -10%;
    right: auto;
    bottom: 0;
    width: 200%;
    height: 70%;
    max-width: none;
  }

  .subtitle {
    padding: 20px 0 0;
    font-family: Unbounded;
  }

  ._title_aboutUs {
    font-size: 30px;
  }

  .title_color {
    font-size: 30px;
    font-family: Unbounded;
  }
}