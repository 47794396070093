@import "../../../stylesGlobal/globalStyles.module.css";


.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 0 auto;
}

.header_row {
  display: flex;
  justify-content: space-between;
  color: #ffffff;

}

.img_logo {
  /*background-image: url("../common/svg/Group.png");*/
  /*height: 110px !important;*/
}

.hedaer_logo_a {}

.header_logo {
  height: 141px;
  border: 1px solid #484848;
  border-top: 0;
  padding: 15px 20px;

  .img_logo {
    height: 100%;
    width: 120px;
  }
}

.header_menu {
  display: flex;
  width: 100%;
  align-items: center;
  height: 81px;
  border-right: 1px solid #484848;
  border-bottom: 1px solid #484848;
  justify-content: space-between;
}

.header_nav {
  display: flex;
  gap: 40px;
  padding: 0px 60px 0px 60px;
}

.header_nav_text {
  white-space: nowrap;
}

.color_text {
  color: var(--yellow);
}

.header_support {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 20px 0px 20px;
}

.header_social_img {}

.header_social {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px 20px 0px 20px;
}

.hedaer_web_img {}

.header_web {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  width: 40px;

  .hedaer_web_img {
    width: 20px;
    height: 20px;
  }
}

.vertical_line {
  height: 100%;
  width: 1px;
  background-color: #484848;
}

.mobile_menu {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 85px;
  left: -100%;
  padding-top: 50px;
  overflow: auto;
  background-color: #333;
  background-image: url("../../../common/img/dark_bg.png");
  transition: left 0.9s;

}

@media (max-width: 1300px) {
  .header_nav {
    display: none;
    overflow: auto;
  }

  .wraper_header_nav2 {
    overflow: auto;
    max-height: 80%;
  }

  .header_nav2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    color: var(--white);
  }

  .active2 {
    left: 0;

  }

  .btn_active {
    margin-top: 10%;
    color: var(--yellow);
    display: none;
  }

  .language_burger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    color: var(--grey);

  }

  .language_burger:hover,
  .language_burger:focus {
    color: var(--yellow);
  }

  .header_row {
    z-index: 8;
  }

  .header_menu {
    justify-content: flex-start;
  }

  /*  */
  .active {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    z-index: 9;
    overflow: auto;
    transition: top 0.5s;
  }

  .header_social {
    justify-content: center;
    margin: 0px 20px;
    flex: 1 0 40%;
  }

  .header_web {
    display: none;
  }

  .burger_wrapper {
    justify-content: center;
    margin: 0 30px;
    display: flex;
  }

  .header_logo {
    padding: 0 20px;
    border-right: 0;
    height: 81px;

    .a {
      height: 79px;
    }

    .img_logo {
      height: 78px;
      width: 65px;
    }
  }
}

@media (max-width: 800px) {
  .container {
    padding: 0 20px;
  }

  .header_nav2 {
    margin-bottom: 100px;
  }

  .header_logo {
    padding: 0 2px;
  }

  .header_menu {
    justify-content: center;
  }

  .btn_active {
    display: block;
  }

  .header_social {
    flex: 1 0 40%;
    margin: 0px 10px;
  }

  .header_support {
    display: none;
  }

  .burger_wrapper {
    margin: 0 20px;
  }

  @media (max-width: 425px) {

    .grid_wraper {
      display: grid;
      grid-template-columns: 1fr auto auto auto 1fr;

      border: 1px solid var(--grey);
      height: 81px;
    }

    .grid_item1 {
      border-right: 1px solid var(--grey);
      grid-row: 1/ 5;

      .img {
        height: 60px;
        width: 45px;
      }
    }

    .header_social {

      margin: 0px 10px;

      .header_social_img {
        height: 35px;
        width: 35px;
      }
    }

    .burger_wrapper {
      height: 20px;
      width: 20px;
    }

    .icon_twitter {
      height: 35px;
      width: 35px;
    }

    .header_logo {
      padding: 15px 10px;
      height: 51px;

      .a {
        height: 100%;
      }

      .img_logo {
        height: 55px;
        width: 44px;
      }
    }
  }
}