@import "../../../stylesGlobal/globalStyles.module.css";

.header {
  position: relative;
  background-image: url("../../../common/img/dark_bg.png");
}

.nav_Menu {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.container {
  max-width: 1440px;
  padding: 0 0px;
  margin: 0 auto;
  position: relative;
  height: 100vh;
}

.block_left {
  position: relative;
  z-index: 10;
  /* margin: 250px 0 0 70px; */
  top: 250px;
  margin-left: 70px;
}

.header_title1 {
  max-width: 220px;
}

.header_title_dron {
  /* margin-top: 220px; */
  width: 100%;
  font-size: 100px;
  max-width: 605px;
}

.header_title2 {}

.header_title1_3D {
  /* margin-top: 230px; */
  font-size: 80px;
}

.header_title2_3D {
  font-size: 80px;
}

.header_text16B {
  color: var(--white);
  margin-top: 10px;
  max-width: 290px;
}

.btn_to_partner {
  position: absolute;
  z-index: 10;
  bottom: 10%;
  left: 70px;
  font-family: none;
}

.btn_about {
  position: absolute;
  z-index: 10;
  bottom: 10%;
  left: 370px;

  @media (max-width: 1024px) {
    left: 70px;
    bottom: 20%;
  }

  @media (max-width: 768px) {
    bottom: 18%;
  }
}

.btn_flex {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.img_banner1 {
  position: absolute;
  bottom: 0;
  /*right: 0;*/
  /*max-width: 76vw;*/
  /*max-height: 95vh;*/
  background-image: url('../../../common/img/desktop_banner_1.png');
  max-width: 100%;
  max-height: 100%;
  height: 90%;
  /* Set background size, position, and repeat */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  /* Adjust as needed */

  /* Set other styles for the div */
  width: 100%;
  /* Adjust as needed */
}

.img_banner2 {
  position: absolute;
  bottom: 0;
  /*right: 0;*/
  /*max-width: 76vw;*/
  /*max-height: 95vh;*/
  background-image: url('../../../common/img/desktop_banner_2.png');
  max-width: 100%;
  max-height: 100%;
  height: 90%;
  /* Set background size, position, and repeat */
  background-size: contain;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  /* Adjust as needed */

  /* Set other styles for the div */
  width: 100%;
  /* Adjust as needed */
}


.img_banner3 {
  position: absolute;
  bottom: 0;
  /*right: 0;*/
  /*max-width: 76vw;*/
  /*max-height: 95vh;*/
  background-image: url('../../../common/img/desktop_banner_3.png');
  max-width: 100%;
  max-height: 100%;
  height: 90%;
  /* Set background size, position, and repeat */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  /* Adjust as needed */

  /* Set other styles for the div */
  width: 100%;
  /* Adjust as needed */
}

.bg_bunner4 {
  background-image: url("../../../common/img/desktop_banner_4.png");
  background-repeat: no-repeat;
  /* background-position: right top; */
  background-size: cover;
}

.img_banner4 {
  position: absolute;
  bottom: 0;
  /*right: 0;*/
  /*max-width: 76vw;*/
  /*max-height: 95vh;*/
  background-image: url('../../../common/img/desktop_banner_4.png');
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  /* Set background size, position, and repeat */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  /* Adjust as needed */

  /* Set other styles for the div */
  width: 100%;
  /* Adjust as needed */
}

.img_banner5 {
  position: absolute;

  right: 0;
  top: 33%;

}

.img_banner6 {
  position: absolute;
  bottom: 10%;
  right: 5%;
  height: 500px;
}

.header_icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.arrow_left {
  position: absolute;
  right: 20%;
  bottom: 40px;
  transform: translateY(-50%);
  background-color: transparent;
  z-index: 8;
}

.arrow_right {
  position: absolute;
  right: calc(20% - 90px);
  bottom: 40px;
  transform: translateY(-50%);
  background-color: transparent;
}

.swipe_wrapper {
  display: none;
}

@media (max-width: 1300px) {
  .arrow_left {
    position: absolute;
    right: 30%;
    bottom: 40px;
    transform: translateY(-50%);
    background-color: transparent;
    z-index: 8;
  }

  .arrow_right {
    position: absolute;
    right: calc(30% - 90px);
    bottom: 40px;
    transform: translateY(-50%);
    background-color: transparent;
  }

  .bg_bunner4 {
    background-position: right top;
  }

  .block_left {
    top: 180px;

  }

  .header_title1 {
    width: 100%;
    font-size: 80px;
  }

  .header_title_dron {
    font-size: 80px;
  }


  .header_text16B {
    max-width: 385px;
  }

  .img_banner1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    /*max-height: 550px;*/
    background-image: url('../../../common/img/desktop_banner_1.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    /*max-height: 550px;*/
    background-image: url('../../../common/img/desktop_banner_2.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: contain;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner3 {
    position: absolute;
    bottom: 0;
    /*right: 0;*/
    /*max-width: 76vw;*/
    /*max-height: 95vh;*/
    background-image: url('../../../common/img/desktop_banner_3.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner4 {
    position: absolute;
    bottom: 0;
    /*right: 0;*/
    /*max-width: 76vw;*/
    /*max-height: 95vh;*/
    background-image: url('../../../common/img/desktop_banner_4.png');
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner5 {
    width: 50%;
    bottom: 0px;
  }


}

@media (max-width: 1024px) {
  .arrow_left {
    position: absolute;
    right: 30%;
    bottom: 40px;
    transform: translateY(-50%);
    background-color: transparent;
    z-index: 8;
  }

  .arrow_right {
    position: absolute;
    right: calc(30% - 90px);
    bottom: 40px;
    transform: translateY(-50%);
    background-color: transparent;
  }

  .container {
    height: 100vh;
  }

  .block_left {
    top: 150px;
  }

  .header_title1 {
    font-size: 70px;
  }

  .btn_flex_3D {
    margin-top: 0px;
  }

  .btn_flex {
    margin-top: 0px;
  }

  .header_title_dron {
    font-size: 80px;
    max-width: 605px;
  }

  .header_title1_3D {
    font-size: 70px;
  }

  .header_title2_3D {
    font-size: 70px;
  }

  .img_banner1 {
    position: absolute;
    bottom: 0;
    /* width: 100%;
    height: auto; */
    /*max-width: 76vw;*/
    background-image: url('../../../common/img/desktop_banner_1.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner2 {
    position: absolute;
    bottom: 0;
    /* width: 100%;
    height: auto; */
    /*max-width: 76vw;*/
    background-image: url('../../../common/img/desktop_banner_2.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: contain;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner3 {
    position: absolute;
    bottom: 0;
    /*right: 0;*/
    /*max-width: 76vw;*/
    /*max-height: 95vh;*/
    background-image: url('../../../common/img/desktop_banner_3.png');
    max-width: 100%;
    max-height: 100%;
    height: 90%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner4 {
    position: absolute;
    bottom: 0;
    /*right: 0;*/
    /*max-width: 76vw;*/
    /*max-height: 95vh;*/
    background-image: url('../../../common/img/desktop_banner_4.png');
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner5 {
    right: 0;
    bottom: 0;


  }

  .img_banner6 {
    bottom: 10%;
    width: 341px;
    height: 400px;
    height: 50vh;
    width: 45vh;
  }
}

@media (max-width: 768px) {
  .swipe_wrapper {
    display: flex;
    margin: 20px 0 0 20px;
    gap: 10px;
    position: absolute;
    bottom: 10%;
    right: 5%;
  }

  .swipeRight_title {
    color: #B1B1B1;
    text-align: center;
    font-family: Unbounded;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .container {
    height: 100vh;
    /* width: 100vw; */
  }

  .bg_bunner4 {
    background-position-x: -315px;
  }

  .block_left {
    top: 100px;
    margin-left: 20px;
  }

  .img_banner1 {
    /* max-height: 45%;
    width: 100%;
    height: auto; */
    position: absolute;
    bottom: 0;
    background-image: url('../../../common/img/mobile_banner_1.svg');
    /* Adjust the path if needed */

    max-width: 100%;
    max-height: 100%;
    height: 55%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .header_title1 {
    width: 70px;
    font-size: 70px;
  }

  .header_title1_3D {
    font-size: 70px;
  }

  .header_title2_3D {
    font-size: 70px;
  }

  .header_title_dron {
    font-size: 70px;

  }

  .img_banner2 {
    position: absolute;
    bottom: 0;
    background-image: url('../../../common/img/mobile_banner_2.svg');
    /* Adjust the path if needed */

    max-width: 100%;
    max-height: 100%;
    height: 55%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner3 {
    position: absolute;
    bottom: 0;
    background-image: url('../../../common/img/mobile_banner_3.svg');
    /* Adjust the path if needed */

    max-width: 100%;
    max-height: 100%;
    height: 55%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner4 {
    position: absolute;
    bottom: 0;
    background-image: url('../../../common/img/mobile_banner_4.svg');
    /* Adjust the path if needed */

    max-width: 100%;
    max-height: 100%;
    height: 100%;
    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner5 {
    max-height: 300px;
    max-width: 400px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
  }

  .img_banner6 {
    left: 50%;
    transform: translateX(-50%);
    bottom: 2%;
    max-height: 450px;
    max-width: 400px;
  }

  .btn_flex {
    flex-direction: column;
  }

  .btn_flex_3D {
    flex-direction: column;
  }

  .btn_to_partner {
    bottom: 8%;
  }

  .arrow_left {
    /* transform: rotate(90deg);
    right: 30px;
    bottom: 110px; */
    display: none !important;
  }

  .arrow_right {
    /* transform: rotate(90deg);
    right: 30px;
    bottom: 40px; */
    display: none !important;
  }
}

@media (max-width: 425px) {
  .container {
    display: flex;
    height: calc(100vh - 120px);
    flex-direction: column;
    padding-top: 120px;
    align-items: center;
    justify-content: space-between;
  }

  .block_left {
    position: static;
  }

  .img_banner1 {
    /* position: static;
    transform: translateX(0);
    height: 45%;
    width: 100%; */
    /*position: absolute;*/
    /*bottom: 0;*/
    max-width: 100%;
    max-height: 100%;
    height: 55%;
    background-image: url('../../../common/img/mobile_banner_1.svg');
    /* Adjust the path if needed */

    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */

  }

  .img_banner2 {
    max-width: 100%;
    max-height: 100%;
    height: 55%;
    background-image: url('../../../common/img/mobile_banner_2.svg');
    /* Adjust the path if needed */

    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */

  }

  .img_banner3 {
    max-width: 100%;
    max-height: 100%;
    height: 55%;
    background-image: url('../../../common/img/mobile_banner_3.svg');
    /* Adjust the path if needed */

    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .img_banner4 {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    background-image: url('../../../common/img/mobile_banner_4.svg');
    /* Adjust the path if needed */

    /* Set background size, position, and repeat */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    background-repeat: no-repeat;
    /* Adjust as needed */

    /* Set other styles for the div */
    width: 100%;
    /* Adjust as needed */
  }

  .bg_bunner4 {
    background-position: 68% center;
    height: 100vh;
  }

  .img_banner5 {

    height: 200px;
    width: 100%;
    bottom: 0;
    /* transform: translateY(-50%); */
  }

  .img_banner6 {
    /* bottom: 12%; */
    position: static;
    transform: translate(0, -80px);
    height: 30vh;
    width: 25vh;
  }

  .btn_about {
    left: 20px;
  }

  .btn_to_partner {
    left: 20px;
  }

  .header_title1_3D {
    font-size: 55px;
  }

  .header_title_dron {
    font-size: 55px;

  }
}