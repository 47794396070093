@import "../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 70px 70px 70px;
  margin: 100px auto 100px auto;
}

.title_block {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.btn_slider {
  display: flex;
  gap: 10px;
}

.round_bg_slide_img {}

.round_bg_slide {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;

  .round_bg_slide_img {
    margin: 0 auto;
  }
}

.arrow_left {
  position: absolute;
  right: 70px;
  top: -70px;
  transform: translateY(-50%);
}

.arrow_right {
  position: absolute;
  right: 0px;
  top: -70px;
  transform: translateY(-50%);
}

.wrapper_block {
  margin: 40px 0 20px 0;
  display: flex;
  gap: 20px;
}

.item_block_img {}

.item_block {
  padding-right: 20px;

  .item_block_img {
    max-width: 100%;
  }
}

.item_block:not(:last-child) {
  padding-right: 20px;
}

.text_block {
  display: flex;
  position: relative;
  margin-top: 30px;
}

._text16B {
  color: #171717;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.8px;
  max-width: 300px;
}

/* BtnRoundArrow */
.round_bg_img {}

.round_bg {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  background-color: var(--white);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .round_bg_img {
    margin: 0 auto;
  }
}


@media (max-width: 1024px) {
  .finish_title {
    font-size: 34px;
    max-width: 350px;
  }

  .item_block> :nth-child(2) {
    padding-right: 0px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px 20px 20px;
    margin-bottom: 80px;
  }

  .item_block {
    padding-right: 0px;
    max-width: 98%;

  }

  .arrow_left_img {}

  .arrow_left {
    height: 40px;
    width: 40px;
    right: 70px;
    top: -35px;

    .arrow_left_img {
      width: 25px;
    }
  }

  .arrow_right_img {}

  .arrow_right {
    height: 40px;
    width: 40px;
    top: -35px;
    right: 20px;

    .arrow_right_img {
      width: 25px;
    }
  }
}

@media (max-width: 425px) {
  .title_block {
    margin-bottom: 80px;
  }

  .item_block {
    padding-right: 20px;
    padding-bottom: 20px;
    margin: 0px 10px;
  }

  ._text16B {
    max-width: 180px;

  }
}