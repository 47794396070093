.section {
  position: relative;
  height: 428px;
  background-image: url("../../common/svg/Brotherhood.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 100px 70px 0;
  width: calc(100% - 140px);
  background-position: right;
}

.title {
  font-family: Unbounded;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  width: 780px;
  margin: 0;
  color: #ffffff;
}

.values {
  overflow-x: auto;
  overflow-y: clip;
  height: 80px;
  border: 1px solid #484848;
  border-radius: 50px;
  margin-top: 56px;
  display: -webkit-box;
  gap: 20px;
  align-items: center;
  box-sizing: border-box;
}

.values_title {
  flex: 0 0 auto;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 18.2px */
  letter-spacing: 0.7px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.swipe_wrapper {
  display: none;

}

@media (max-width: 1024px) {
  .title {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .section {
    margin-top: 50px;
    padding: 0;
    width: 100%;
  }

  .title {
    font-size: 34px;
    width: calc(100% - 40px);
    padding: 70px 20px 0 20px;

  }

  .values {
    margin: 30px 0 0 20px;
    max-width: calc(100% - 40px);
  }

  .swipe_wrapper {
    display: flex;
    margin: 20px 0 0 20px;
    gap: 20px;
  }

  .swipeRight_title {
    color: #B1B1B1;
    text-align: center;
    font-family: Unbounded;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

}