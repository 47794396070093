@import "../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 70px;
  margin: 70px auto;
}

.charity_title {
  max-width: 750px;
}

._text14B {
  width: 530px;
  margin-top: 20px;
}

.charity_item {
  margin-top: 90px;
  display: flex;
}

.title_item {
  font-size: 30px;
  width: 420px;
}

.title_item_2 {
  font-size: 30px;
  width: 520px;
}

.btn_wrapper {
  display: flex;
  margin-top: 40px;
  gap: 10px;
}

._text12Fw4 {
  margin-left: 130px;
}

.two_text12Fw4 {
  margin-left: 30px;
}

.horizontal_line {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .charity_item {
    margin-top: 90px;
    flex-direction: column;
    /* align-items: center; */
  }

  ._text12Fw4 {
    padding-top: 40px;
    margin-left: 0px;
  }

  .two_text12Fw4 {
    padding-top: 40px;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 60px;
    padding: 0 20px;
  }

  .charity_item {
    align-items: center;
  }

  ._text14B {
    width: 100%;
  }

  ._text12Fw4 {
    width: 100%;
  }

  .title_item {
    font-size: 18px;
    width: 100%;
  }

  .title_item_2 {
    font-size: 18px;
    width: 100%;
  }

  .btn_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn_read {
    border: 1px solid var(--grey);
    background-color: var(--white);
    height: 60px;
    width: 160px;
    border-radius: 80px;
    padding: 20px 10px;
    font-size: 14px;
    cursor: pointer;
  }
}

@media (max-width: 425px) {
  .btn_read {
    width: 130px;
    font-size: 12px;
    font-weight: 400;
    font-family: Unbounded;
  }
}