@import "../../stylesGlobal/globalStyles.module.css";

.section {}

.container {
  max-width: 1440px;
  padding: 100px 70px;
  margin: 0px auto;
}

.block_title {
  display: flex;
  justify-content: space-between;
}

._title {
  max-width: 600px;
}

.title_color {
  color: var(--yellow);
}

.slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.block_certificates {
  margin-top: 45px;
  text-align: center;
}

.border_logo_img {}

.border_logo {
  position: relative;
  max-width: 310px;
  height: 370px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  margin-inline: 0px;
  padding: 10px;

  .border_logo_img {
    margin: 0 auto;
  }
}

.swipe_wrapper {
  display: none;

}

.arrow_left {
  position: absolute;
  right: 70px;
  top: -70px;
  transform: translateY(-50%);
}

.arrow_right {
  position: absolute;
  right: 0px;
  top: -70px;
  transform: translateY(-50%);
}

@media (max-width: 1023.8px) {}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px;
  }

  .block_title {
    display: block;
  }

  .border_logo {
    max-width: 260px;
    height: 346px;
    margin: 0px 20px 0px 20px;

    .border_logo_img {
      width: 215px;
      height: 340px;
    }
  }

  .swipe_wrapper {
    display: flex;
    margin: 20px 0 0 20px;
    gap: 20px;
  }

  .swipeRight_title {
    color: #B1B1B1;
    text-align: center;
    font-family: Unbounded;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .arrow_left_img {}

  .arrow_left {
    height: 40px;
    width: 40px;
    right: 120px;
    top: 415px;

    .arrow_left_img {
      width: 25px;
    }
  }

  .arrow_right_img {}

  .arrow_right {
    height: 40px;
    width: 40px;
    top: 415px;
    right: 70px;

    .arrow_right_img {
      width: 25px;
    }
  }
}

@media (max-width: 500px) {
  ._title {
    font-size: 30px;
    max-width: 320px;
  }

  .border_logo {

    max-width: 260px;
    height: 346px;

    .border_logo_img {
      width: 215px;
      height: 340px;
    }
  }
}