@import "../../stylesGlobal/globalStyles.module.css";

.section {
  overflow: hidden;
}

.container {
  position: relative;
  max-width: 1440px;
  padding: 0px 70px;
  margin: 70px auto;
  height: 100%;
}

.team {
  display: flex;
  justify-content: space-between;
  height: 130px;
  position: relative;
}

.team_footer_img {}

.team_footer {
  position: absolute;
  top: 0;
  right: 70px;
  display: flex;
  justify-content: flex-end;

  .team_footer_img {
    object-fit: cover;
    transition: all 0.3s ease;
    padding-right: 20px;
  }

  @media (max-width: 1300px) {
    position: unset;
    margin: 80px auto 0 auto;
    justify-content: center;
  }
}

.title {
  font-family: Unbounded;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  max-width: 580px;
  padding-right: 20px;
  color: #171717;
}

.button {
  width: 130px;
  height: 130px;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  background: #ffffff;
}

.btn_text {
  color: #171717;
  text-align: center;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.stats_wraper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  margin: 60px 0 0;
}

.stats_title_span {}

.stats_title {
  color: #fcb813;
  font-family: Unbounded;
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 3px;
  text-transform: uppercase;
  white-space: nowrap;

  .stats_title_span {
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.8px;
    white-space: nowrap;
  }

  @media (max-width: 425px) {
    text-align: left;
  }
}

.stats_subtitle {
  color: #171717;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 10px;

  @media (max-width: 425px) {
    text-align: left;
  }
}

.stats_span {
  color: #171717;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.bottom_line_img {
  /* height: 40px; */
  background-image: url("../../common/svg/line_bottom.png");
}

@media (max-width: 1024px) {
  .stats_wraper {
    gap: 25px;
    margin: 60px 0px 0;
    text-align: center;
  }

  .stats_title {
    font-size: 50px;
  }

  .team_footer {
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 60px 0px 0 0px;
    gap: 30px;

    .team_footer_img {
      padding-right: 0px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px;
    padding: 0 20px;
    width: auto;
  }

  .team_footer {
    display: flex;
    margin: 60px 20px 0 20px;

    .team_footer_img {
      width: 100%;
      max-width: 250px;
      height: 130px;
      border-radius: 65px;
    }
  }

  .title {
    font-size: 34px;
    padding-right: 00px;
    max-width: 445px;
  }

  .stats_wraper {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 0;
    text-align: center;
  }

  .stats_title {
    font-size: 50px;
    letter-spacing: 2.5px;
  }
}

.bottom_line_img {
  height: 40px;
}

@media (max-width: 425px) {
  .section {
    height: 100%;
    margin-top: 0px;
  }

  .title {
    font-size: 30px;
    width: 100%;
    padding: 0;
  }

  .values {
    margin: 36px 0 0 20px;
  }

  .stats_wraper {
    gap: 10px;
    justify-content: center;
    margin-top: 0;
  }

  .stats_title {
    font-size: 50px;
    letter-spacing: 2.5px;
  }

  .team_footer {
    flex-direction: row;
    height: 100%;
    gap: 10px;
    justify-content: center;
    margin: 40px 0 0 0;

    .team_footer_img {
      width: 100%;
      padding: 0;
      max-width: 200px;
    }
  }

  .button {}

  .btn_text {
    font-size: 12px;
  }
}