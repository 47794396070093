@import "../../stylesGlobal/globalStyles.module.css";



.container {
  max-width: 1440px;
  padding: 0px 70px;
  margin: 100px auto;
}

.h2 {
  font-family: Unbounded;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 62.4px */
  text-transform: uppercase;
}

.charity_h5 {}

.charity {
  margin-top: 40px;

  .charity_h5 {
    color: #171717;
    font-family: Unbounded;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
}

.organization_logo {
  position: relative;
  margin-top: 30px;
  /* display: flex; */
  flex-wrap: wrap;
  gap: 10px;

}

.border_logo_img {}

.border_logo {
  position: relative;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;

  .border_logo_img {
    width: 106px;
    margin: 0 auto;
  }
}

.company_h5 {}

.company {
  margin-top: 40px;

  .company_h5 {
    color: #171717;
    font-family: Unbounded;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
  }
}

.busines_logo {
  margin-top: 30px;
  flex-wrap: wrap;
  /* display: flex; */
  gap: 10px;

}

.arrow_left_img {}

.arrow_left {
  position: absolute;
  right: 70px;
  top: -60px;
  transform: translateY(-50%);
}

.arrow_right_img {}

.arrow_right {
  position: absolute;
  right: 0px;
  top: -60px;
  transform: translateY(-50%);
}

@media (max-width: 1110px) {
  .header_nav {}

  .header_support {}
}

@media (max-width: 768px) {
  .partners {
    height: 100%;


  }

  .container {
    margin: 80px 0;
    padding: 0px 20px 0;

  }

  .arrow_left {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 70px;
    top: -40px;


    .arrow_left_img {
      width: 25px;
    }
  }

  .arrow_right {
    position: absolute;
    height: 40px;
    width: 40px;
    top: -40px;
    right: 20px;

    .arrow_right_img {
      width: 25px;
    }
  }
}

@media (max-width: 424.8px) {
  .container {
    margin: 80px 0;

  }

  .h2 {
    font-size: 34px;
  }

  .charity {

    .charity_h5 {
      width: 200px;
    }
  }
}