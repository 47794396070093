.title {
  font-family: Unbounded;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  margin-right: 20px;

  @media (max-width: 500px) {
    font-size: 34px;
    margin-right: 0px;
  }
}

.title_aboutUs {
  font-family: Unbounded;
  font-size: 65px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
}

.header_title {
  color: #FFF;
  font-family: Unbounded;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 151.642px */
  letter-spacing: 5.832px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 60px;
  }
}

/* Our Team */
.text16B {
  color: var(--secondary-black);
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.8px;
}

.text16 {
  color: var(--white);
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.8px;
}

.text12Fw4 {
  color: #1a181d;
  font-family: Unbounded;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  /* 24px */
}

.text14Fw4 {
  color: #171717;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: Unbounded;
}

.text14B {
  color: black;
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.7px;
}

.about_block_text {
  color: var(--grey, #b1b1b1);
  font-family: Unbounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.7px;
}

.text18B {
  color: var(--main-black, #171717);
  font-family: Unbounded;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
}

.text10Fw4 {
  color: var(--grey, #b1b1b1);
  font-family: Unbounded;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

.button_arrow {
  position: relative;
  display: flex;
  height: 80px;
  border: 1px solid #e2e2e2;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.copy_button_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.round_bg_img {
  cursor: pointer;
}

.round_bg {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #E2E2E2;
  background-color: var(--white);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;

  .round_bg_img {
    margin: 0 auto;
  }
}

/* Button  requisites UAH, USD, Crypto*/
.button_copy {
  position: relative;
  display: flex;
  height: 80px;
  max-width: 640px;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: auto;
}

.round_bg_copy {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #E2E2E2;
  background-color: var(--white);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;

  .round_bg_img {
    margin: 0 auto;
  }
}

/* END*/

.arrow_image {}

/* Button slider*/
.round_bg_slide {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #E2E2E2;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow_image {
    margin: 0 auto;
  }
}

/* Flag Round in HistoryFund */
.round_bg_history_img {}

.round_bg_history {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #E2E2E2;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;

  .round_bg_history_img {
    margin: 0 auto;
  }
}