@import "../../stylesGlobal/globalStyles.module.css";

.container {
  max-width: 1440px;
  padding: 0 0px;
  margin: 100px 0;
}

.afterVictory {
  background-image: url("../../common/img/dark_bg.png");
}

.victory_wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
}

.block_left {

  /* background-repeat: no-repeat; */
  width: 50%;
  padding: 0px 20px 0 70px;
}

.top_text {
  color: var(--white);
  margin-top: 100px;
}

.victory_title {
  color: var(--yellow);
  margin-top: 20px;
}

.top_text12Fw4 {
  color: var(--white);
  margin-top: 30px;
  max-width: 530px;
}

.bolt_text14B {
  color: var(--white);
  margin-top: 50px;
  max-width: 530px;
}

.text12Fw4_3 {
  color: var(--white);
  margin-top: 20px;
  padding-bottom: 100px;
  max-width: 530px;
}

.block_right {
  width: 60%;
  background-image: url("../../common/img/boy-with-ukrainian-flag.png");
  background-size: cover;
  background-position: center;
}

.victory_wrapper {}

@media (max-width: 1110px) {
  .header_nav {}

  .header_support {}
}

@media (max-width: 900px) {
  .victory_wrapper {
    display: block;
    /* flex-direction: column;
    align-items: center; */
    /* align-items: center; */
  }

  .top_text {
    margin-top: 0px;
    padding-top: 70px;
  }

  .block_left {
    width: calc(100% - 40px);
    padding: 0 20px;
  }

  .block_right {
    width: 100%;
    background-image: url("../../common/img/boy-with-ukrainian-flag.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: 0px;
  }

  .text12Fw4_3 {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0px 0;
  }

  .block_right {
    height: 460px;
  }

  .text12Fw4_3 {
    padding-bottom: 50px;
  }
}

@media (max-width: 424.8px) {
  .container {
    margin: 80px 0;
  }

  .block_left {
    height: 100%;
    flex: 1;
  }

  .block_right {
    height: 410px;
  }

  .text12Fw4_3 {
    padding-bottom: 0px;
    padding-bottom: 20px;
  }
}